<template>
  <div class="results-wrapper">
    <div class="result-details">
      <p class="name">{{ name }}</p>
      <p class="result">{{ result }}</p>
    </div>
    <div class="result-indicator" :style="`width: ${progressValue}%`"></div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'PollResults',
  components: { },
  props: {
    name: {
      type: String,
      required: true,
    },
    result: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const progressValue = ref(null)

    function updateProgress() {
      progressValue.value = props.result !== 0 ? props.result / props.item.totalStudentsAnswered * 100 : 0
    }
    updateProgress()

    return {
      progressValue
    }
  }
}
</script>

<style scoped lang="scss">
  @import "~@/assets/css/_base.variables.scss";
  @import "~@/assets/css/_base.mixins.scss";

  .results-wrapper {
    width: 100%;
    background-color: var(--gray_light);
    padding: rem(10);
    border-radius: rem(6) rem(6) rem(2) rem(2);
    position: relative;
    overflow: hidden;

    &:not(:last-child) {
      margin-bottom: rem(14);
    }

    .result-details {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .name {
        color: var(--blue_dark);
        font-size: rem(16);
      }

      .result {
        color: var(--blue_dark-03);
        font-size: rem(14);
        opacity: .5;
        font-weight: 600;
      }
    }
  }

  .result-indicator {
    background-color: red;
    height: rem(2);
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: rem(2);
  }

</style>