<template>
  <div class="poll-stat-wrapper">
    <p class="mission-name">Missie: {{ item.missionName }}</p>
    <p class="poll-question">{{ item.missionSlideQuestion }}</p>
    <PollResults v-for="result in item.answers"
                 :key="result.answer"
                 :name="result.answer"
                 :result="result.voteCount"
                 :item="item"/>
    <div class="divider"></div>
    <div class="stats-wrapper">
      <p class="stat-name">Aantal klassen: <span class="stat-number">{{ item.totalClassesAnswered }}</span></p>
      <p class="stat-name">Totaal aantal antwoorden: <span class="stat-number">{{ item.totalStudentsAnswered }}</span></p>
    </div>
  </div>
</template>

<script>
import PollResults from './PollResults.vue'
import { ref } from 'vue'

export default {
  name: 'PollStatsBox',
  components: { PollResults },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    return {
    }
  }
}
</script>

<style scoped lang="scss">
  @import "~@/assets/css/_base.variables.scss";
  @import "~@/assets/css/_base.mixins.scss";

  .poll-stat-wrapper {
    background-color: #fff;
    padding: rem(16) rem(32) rem(29) rem(32);
    border-radius: rem(8);
    width: rem(550);

    .mission-name {
      color: var(--blue_dark);
      opacity: .6;
      font-size: rem(14);
      text-transform: uppercase;
      margin-bottom: rem(14);
    }

    .poll-question {
      color: var(--blue_dark_02);
      font-size: rem(16);
      font-weight: 600;
      margin-bottom: rem(14);
      line-height: rem(22);
    }

    .divider {
      background-color: var(--blue_dark);
      height: rem(1);
      margin-bottom: rem(26);
      margin-top: rem(22);
      opacity: .15;
      width: 100%;
    }

    .stats-wrapper {
      display: flex;

      .stat-name {
        color: var(--blue_dark-03);
        opacity: .7;

        &:not(:last-child) {
          margin-right: rem(36);
        }
        
        .stat-number {
          font-weight: 600;
        }
      }
    }
  }
</style>