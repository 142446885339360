<template>
  <div class="stats-box-wrapper">
    <h1 class="stats-title">
      {{ title }}
    </h1>
    <ul class="stats-list">
      <li v-for="(value,index) in values" :key="index" class="list-item">
        {{ value.name }}: <b>{{ value.value }}</b>
      </li>
    </ul>
  </div>
</template>

<script>
// import RollingNumber from '../basic/RollingNumber.vue'
export default {
  name: 'StatBox',
  props: {
    title: {
      type: String,
      required: true,
    },
    values: {
      type: Array,
      required: true,
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";


.stats-box-wrapper {
  background:white;
  padding:rem(32);
  border-radius:rem(16);
  box-shadow: rem(4) rem(4) 0 0 rgba(0, 0, 0, 0.15);
  //transition: all .2s ease-in-out;

  //&:hover {
  //  transform: scale(1.1);
  //  background: #CCE5FF;
  //}
  // min-width:rem(550);
  // min-height: rem(50);
}

.stats-title {
  margin-left: rem(20);
}

.stats-list {
  padding-left: rem(21);
}

.list-item {
  line-height: rem(50);
  font-size: rem(20)
}
</style>
